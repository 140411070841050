import React from "react";

const CollectionnameContext = React.createContext(

    {collectionname:"",
    Changecollectionname:()=>{},
    }

)

export default CollectionnameContext;